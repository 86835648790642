@font-face {
  font-family: 'Poppins';
  src: url(./assets/fonts/Poppins-Light.ttf) format('truetype');
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: 'Poppins';
  src: url(./assets/fonts/Poppins-Regular.ttf) format('truetype');
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url(./assets/fonts/Poppins-Medium.ttf) format('truetype');
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: 'Poppins';
  src: url(./assets/fonts/Poppins-SemiBold.ttf) format('truetype');
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: 'Poppins';
  src: url(./assets/fonts/Poppins-Bold.ttf) format('truetype');
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: 'Poppins';
  src: url(./assets/fonts/Poppins-ExtraBold.ttf) format('truetype');
  font-style: normal;
  font-weight: 800;
}
